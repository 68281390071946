// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-event-tsx": () => import("./../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-inside-report-tsx": () => import("./../src/templates/inside-report.tsx" /* webpackChunkName: "component---src-templates-inside-report-tsx" */),
  "component---src-templates-events-tsx": () => import("./../src/templates/events.tsx" /* webpackChunkName: "component---src-templates-events-tsx" */),
  "component---src-templates-testimonial-tsx": () => import("./../src/templates/testimonial.tsx" /* webpackChunkName: "component---src-templates-testimonial-tsx" */),
  "component---src-templates-spotlight-volunteer-tsx": () => import("./../src/templates/spotlight-volunteer.tsx" /* webpackChunkName: "component---src-templates-spotlight-volunteer-tsx" */),
  "component---src-templates-donate-tsx": () => import("./../src/templates/donate.tsx" /* webpackChunkName: "component---src-templates-donate-tsx" */),
  "component---src-templates-home-tsx": () => import("./../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-page-story-with-quick-links-tsx": () => import("./../src/templates/page-story-with-quick-links.tsx" /* webpackChunkName: "component---src-templates-page-story-with-quick-links-tsx" */),
  "component---src-templates-page-simple-utility-tsx": () => import("./../src/templates/page-simple-utility.tsx" /* webpackChunkName: "component---src-templates-page-simple-utility-tsx" */),
  "component---src-templates-page-story-with-summary-tsx": () => import("./../src/templates/page-story-with-summary.tsx" /* webpackChunkName: "component---src-templates-page-story-with-summary-tsx" */),
  "component---src-templates-page-private-simple-story-tsx": () => import("./../src/templates/page-private-simple-story.tsx" /* webpackChunkName: "component---src-templates-page-private-simple-story-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

