module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hurley Foundation","short_name":"Hurley Foundation","start_url":"/","background_color":"#f7f0eb","theme_color":"#9564B8","display":"standalone","icon":"src/images/hurley-foundation-favicon.png"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KW4RP6","gtmAuth":"WQ-UVQEVR5rrrPh2gD4v9A","gtmPreview":"env-36"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
